<template>
  <Wrapper>
    <div class="serviceDetail">
      <DetailBanner titleType='2' title=""></DetailBanner>
      <water-title Chinese="服务大厅"></water-title>
      <div class="content">
        <div class="info">
          <el-image :src="imgBaseUrl + serviceData.logopic">
          </el-image>
          <div class="detail">
            <el-descriptions :column="2">
              <template slot="title">
                <h3 style="margin-bottom:10px">
                  <span>{{serviceData.name}}</span>
                  <img style="vertical-align:middle;margin-left:10px"
                    src="@/assets/imgs/service/weirenzheng.png" />
                </h3>
                <img src="@/assets/imgs/service/stars.png" />
              </template>

              <el-descriptions-item>
                <template slot="label">
                  <img src="@/assets/imgs/service/user.png" />
                  <span>联系人</span>
                </template>
                {{ serviceData.contacter }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <img src="@/assets/imgs/service/address.png" />
                  <span>公司地址</span>
                </template>
                {{ serviceData.addr }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <img src="@/assets/imgs/service/phone.png" />
                  <span>联系电话</span>
                </template>
                {{ serviceData.contacttel }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <img src="@/assets/imgs/service/network.png" />
                  <span>公司网站</span>
                </template>
                {{ serviceData.homepageUrl }}
              </el-descriptions-item>
              <el-descriptions-item label="公司简介">
                <template slot="label">
                  <img src="@/assets/imgs/service/company.png" />
                  <span>公司简介</span>
                </template>
                {{ serviceData.decriptionscontent }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
        <el-menu :default-active="companyZzIndex" mode="horizontal">
          <el-menu-item index="1">公司资质</el-menu-item>
        </el-menu>
        <div class="imgs">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in companyZzImgList" :key="index">
              <el-image :src="imgBaseUrl+item"></el-image>
            </el-col>
          </el-row>
        </div>
        <el-menu :default-active="companyPhotoIndex" mode="horizontal">
          <el-menu-item index="1">公司相册</el-menu-item>
        </el-menu>
        <div class="imgs">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in companyPhotoImgList" :key="index">
              <el-image :src="imgBaseUrl+item"></el-image>
            </el-col>
          </el-row>
        </div>
        <el-menu :default-active="companyProductIndex" mode="horizontal">
          <el-menu-item index="1">公司产品</el-menu-item>
        </el-menu>
        <div class="imgs">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in companyProductImgList" :key="index">
              <el-image :src="imgBaseUrl+item"></el-image>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </Wrapper>
</template>
<script>
import Wrapper from '@/components/Wrapper.vue'
import DetailBanner from '@/views/sections/DetailBanner.vue'
import WaterTitle from '@/components/WaterTitle.vue'
import { getServiceDetail } from '@/api/modules/service.js'
import { INFO_URL } from '@/config/globalConfig'
export default {
  components: { Wrapper, DetailBanner, WaterTitle },
  data() {
    return {
      imgBaseUrl: INFO_URL,
      serviceData: {},
      companyZzIndex: '1',
      companyZzImgList: [],
      companyPhotoIndex: '1',
      companyPhotoImgList: [],
      companyProductIndex: '1',
      companyProductImgList: []
    }
  },
  created() {
    let id = this.$route.query.id
    if (id) {
      this.getServiceDetail(id)
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    async getServiceDetail(id) {
      try {
        const { data } = await getServiceDetail(id)
        this.serviceData = data
        if (data.qualitypics) {
          const zizhis = data.qualitypics.split(',')
          this.companyZzImgList = zizhis.map((item) => item)
        } else {
          this.companyZzImgList = []
        }

        this.companyPhotoImgList = data.pic ? data.pic.split(',') : []
        if(data.thumblogopic && data.thumbpic != null){
          this.companyProductImgList.push(data.thumblogopic)
        }
        if(data.thumbpic && data.thumbpic != null){
          this.companyProductImgList.push(data.thumbpic)
        }
      } finally {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.serviceDetail {
  margin-top: 50px;
  ::v-deep .content {
    width: 1200px;
    margin: auto;
    margin-top: 50px;
    .info {
      display: flex;
      .el-image {
        background-color: gray;
        width: 284px;
        height: 326px;
      }
      .detail {
        margin-left: 25px;
        margin-top: 20px;
        flex: 1;
        .el-descriptions {
          .el-descriptions-item__container {
            align-items: flex-start;
          }
          .el-descriptions__title {
            font-size: 24px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #2f3542;
          }
          .el-descriptions-item__label {
            color: #0d162a;
            font-size: 16px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            display: flex;
            align-items: center;
            span {
              margin-left: 5px;
            }
          }
          .el-descriptions-item__content {
            color: #536387;
            font-size: 16px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            margin-left: 10px;
            line-height: 30px;
          }
        }
      }
    }
    .el-menu {
      .el-menu-item {
        font-size: 22px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #181f2d;
      }
    }
    .imgs {
      margin-top: 20px;
      margin-bottom: 30px;
      .el-image {
        width: 390px;
        height: 255px;
        margin-bottom: 25px;
      }
    }
  }
}
</style>


